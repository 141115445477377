<template>
  <b-card-code
    title="Alumnos No Verificados"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    <b-table
      :fields="fields"
      :items="studentLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(name)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>


      <template #cell(status)="">
        <b-badge
          pill
          variant="danger"
        >
          No Verificado
        </b-badge>
      </template>
      <template #cell(id)="data">
      <span >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="verifiedStudent(data.value)">
                
                <span>Mark as Verified</span>
              </b-dropdown-item>
              
            </b-dropdown>
          </span>
        </span>
        </template>
    </b-table>
    <!-- pagination -->
    
        <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol} from 'bootstrap-vue'
import EmailVerification from '@/services/emailVerification.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'name', label: 'Name' },
        // A regular column
        { key: 'email', label: 'Email' },
        // A regular column
        { key: 'created_at', label: 'Created At' },
        { key: 'status', label: 'Status' },
        { key: 'id', label: 'Action' },
      ],
      studentLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
    }
  },
  methods: {
    getUnverifiedStudents() {
                this.showOverlay = true;
                EmailVerification.listing(
                    this.currentPage
                    ).then(response => {
                    this.studentLists = response.data.data.unverifiedStudents.data;
                    this.rows = response.data.data.unverifiedStudents.total;
                    this.currentPage = response.data.data.unverifiedStudents.current_page
                    this.path = response.data.data.unverifiedStudents.path

                    this.pageCount = response.data.data.unverifiedStudents.last_page;
                    this.currentPage = response.data.data.unverifiedStudents.current_page;
                    this.perPageRecord = response.data.data.unverifiedStudents.per_page;
                    this.toRecordCount = response.data.data.unverifiedStudents.to;
                    this.fromRecordCount = response.data.data.unverifiedStudents.from;
                    this.totalRecordCount = response.data.data.unverifiedStudents.total;
                    this.srCounter = (this.currentPage-parseInt(1)) * this.perPageRecord;
                    this.showOverlay = false;
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
    },
    changePage(event) {
      this.getUnverifiedStudents(this.currentPage);
    },
    verifiedStudent(id) {
      this.showOverlay = true;
      EmailVerification.markVerified(id).then(response => {
        const userIndex = this.studentLists.findIndex(user => user.id === id);
        this.studentLists.splice(userIndex, 1);             
        this.showOverlay = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Student Verified Successfully',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        this.showOverlay = false;
        console.log(error);
      });
    }
  },
  created() {
    this.getUnverifiedStudents(this.currentPage);
  }
}
</script>
