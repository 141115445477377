import axiosIns from '@/libs/axios.js';

class EmailVerification {
    listing(pageNumber=1){
        return axiosIns.get('unverified-student?page='+pageNumber).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    markVerified(id){
        return axiosIns.post('verify-user', {
            'id': id
        }).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new EmailVerification();